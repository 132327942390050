var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"width":"440px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.data)?_c('DialogLayout',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-poll")]),_vm._v(" Quick Quote ")]},proxy:true},{key:"dialogContent",fn:function(){return [_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-form',{model:{value:(_vm.isQuantityValid),callback:function ($$v) {_vm.isQuantityValid=$$v},expression:"isQuantityValid"}},[_c('v-text-field',{ref:"QuantityInput",staticClass:"bold-blue-outlined text-box-with-commas",attrs:{"value":_vm.formattedQuantity,"outlined":"","label":"Exact Quantity","rules":[
                () =>
                  _vm.quantity > 0 ||
                  _vm.quantity == null ||
                  'Please enter a number greater than 0',
                () =>
                  _vm.quantity <= 1000000 ||
                  _vm.quantity == null ||
                  'Please enter a number less than 1,000,000',
                () => _vm.quantity % 1 === 0 || 'Please use whole numbers only'
              ],"loading":_vm.isLoading},on:{"input":_vm.handleQuantityInput},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"color":"gray !important"}},[_vm._v(" Exact Quantity ")])]},proxy:true}],null,false,196845555)})],1)],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('span',{staticClass:"actionDialogTitle roboto-black marginRight"},[_vm._v("Sub Total:")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"actionDialogTitle roboto-black j1lorange"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.totalPrice))])]}}],null,false,2414473601)},[_c('span',[_vm._v(_vm._s(_vm.priceEach)+" Each")])])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"bold-blue",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)]},proxy:true}],null,false,2227110394)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }