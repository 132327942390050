
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { OrdersService } from '@/services/orders-service';
import { QuickDialog } from '@/store/models/DialogModel';
import { Item, ItemHistory } from '@/store/models/ItemModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ItemService from '../services/item-service';
import DialogLayout from './DialogLayout.vue';
@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop({ required: true })
  item!: Item;
  /* Store Actions */
  @Getter('isAdmin', { namespace: 'profile' })
  isAdmin!: Boolean;
  /* Watchers */
  /* Data */
  tab: number = 0;
  headers: any = [
    { text: 'Entry Date Time', value: 'EntryDateTime' },
    { text: 'User', value: 'UserName' },
    { text: 'Entry', value: 'Entry' }
  ];
  quickDialogData: QuickDialog | null = null;
  dialog: boolean = false;
  itemHistory: ItemHistory[] = [];
  orderActivities: any[] = [];
  itemHeader: any[] = [
    {
      text: 'Date time',
      align: 'start',
      sortable: true,
      value: 'EntryDateTime'
    },
    {
      text: 'Entry',
      align: 'start',
      sortable: false,
      value: 'Entry'
    },
    {
      text: 'User',
      align: 'start',
      sortable: false,
      value: 'User'
    }
  ];
  orderActivityHeader: any[] = [
    {
      text: 'User name',
      align: 'start',
      sortable: true,
      value: 'UserName'
    },
    {
      text: 'Purchase order',
      align: 'start',
      sortable: true,
      value: 'PO'
    },
    {
      text: 'Quantity',
      align: 'start',
      sortable: true,
      value: 'Quantity'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: false,
      value: 'Status'
    },
    {
      text: 'User name',
      align: 'start',
      sortable: true,
      value: 'UserName'
    }
  ];
  isLoading: boolean = false;
  /* Methods */
  openDialog() {
    this.quickDialogData = ConversionHelper.convertQuickDialog(this.item);
    this.dialog = true;
    this.getItemHistory(this.item.Id);
    this.getOrderActivity(this.item.Id);
  }
  closeDialog() {
    this.dialog = false;
  }
  async getItemHistory(itemId: string) {
    const response = await ItemService.GetItemHistory(itemId);
    this.itemHistory = response.data;
  }

  async getOrderActivity(itemId: string) {
    const response = await OrdersService.GetOrderlinesByItemId(itemId);
    this.orderActivities = response.data;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
