
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '@/store/models/ItemModel';
import { PricingMatrixModel, QuoteData } from '../store/models/QuotesModel';
import { QuoteService } from '@/services/quote-service';
import { ItemService } from '@/services/item-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import DialogLayout from '@/components/DialogLayout.vue';
import { QuickDialog } from '@/store/models/DialogModel';
import { StringHelper } from '@/helpers/StringHelper';

@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  $refs!: {
    QuantityInput: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) item!: Item;
  /* Store Actions */
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.quantity = null;
      this.resetSubtotal();
      this.dialog = false;
    }
  }
  @Watch('quantity')
  onQuantityChange(val: any) {
    this.debounce = true;
    setTimeout(() => {
      this.debounce = false;
    }, 1000);
  }
  @Watch('debounce')
  onDebounce(val: boolean) {
    if (!val) {
      this.waitForSearch();
    }
  }
  @Watch('isQuantityValid')
  onIsQuantityValidChange(val: boolean) {
    if (!val) {
      this.resetSubtotal();
    }
  }
  /* Data */
  isLoading: boolean = false;
  isQuantityValid: boolean = false;
  quantity: number | null = null;
  debounce: boolean = false;
  selectedItem!: Item;
  totalPrice: string = '$0.00';
  priceEach: number = 0;
  dialog: boolean = false;
  data: QuickDialog | null = null;

  /* Methods */

  async waitForSearch() {
    this.isLoading = true;
    await this.GetQuickQuoteValues();
    this.isLoading = false;
  }
  async GetQuickQuoteValues() {
    if (!this.isQuantityValid) return;
    let quantity = Number(this.quantity);
    const model = new PricingMatrixModel({
      DieId: this.selectedItem.Die.Id,
      MaterialId: this.selectedItem.Material.Id,
      FinishId: this.selectedItem.Finish.Id,
      ColorId: this.selectedItem.Color.Id,
      Quantity: quantity,
      StartQuantity: quantity,
      EndQuantity: quantity,
      IncrementBy: 1000,
      ShouldUseVarData: this.selectedItem.HasBarcode,
      ShouldUseVarnish: this.selectedItem.HasVarnish
    });
    const response = await QuoteService.GetQuoteValues(model);
    if (response.data[1]) {
      let totalprice = response.data[1].TotalCost;
      let priceEach = totalprice / this.quantity!;
      this.priceEach = parseFloat(priceEach.toFixed(4));
      this.totalPrice = totalprice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    }
  }
  async openDialog() {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    this.dialog = true;
    await this.getItemDetails();
    this.$refs.QuantityInput.focus();
  }
  async getItemDetails() {
    const response = await ItemService.GetItemDetails(this.item.Id);
    this.selectedItem = new Item(response.data);
  }
  handleQuantityInput(newValue: any) {
    this.quantity = parseInt(newValue.toString().replace(/\D/g, ''));
  }
  resetSubtotal() {
    let totalPrice = 0;
    this.totalPrice = totalPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }
  /* Computed */
  get formattedQuantity() {
    return StringHelper.formatQuantity(this.quantity);
  }
  /* Mounted */
  async mounted() {}
  /* Created */
  created() {}
  /* Created */
}
