
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DialogLayout from './DialogLayout.vue';
import { QuickDialog, AlertModel } from '@/store/models/DialogModel';
import { Item } from '@/store/models/ItemModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import AlertComponent from '@/components/AlertLayout.vue';
import { BuildItemAlertMessage } from '@/helpers/ObjectHelper';
@Component({
  components: { DialogLayout, AlertComponent }
})
export default class QuickItemHoldDialog extends Vue {
  @Prop({ required: true }) item!: Item;
  @Prop() type!: any;

  @Getter('getPosAssociatedWithItem', { namespace: 'items' })
  getAssociatedPos: any;

  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      this.closeDialog();
    }
  }

  dialog: boolean = false;
  data: QuickDialog | null = null;
  openAlert: boolean = false;
  alertOption!: AlertModel;
  async openDialog() {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    await this.$store.dispatch('items/checkForAssociatedOrderlineByItemId', this.item.Id);
    this.dialog = true;
    if (this.getAssociatedPos[0]) {
      this.alertOption = BuildItemAlertMessage('hold', this.item, this.getAssociatedPos);
    }
  }
  closeAlert() {
    this.openAlert = false;
  }
  onAlertConfirm() {
    this.openAlert = false;
    this.$emit('response');
  }
  onItemHoldClick() {
    if (this.getAssociatedPos[0]) {
      this.openAlert = true;
      return;
    }
    this.$emit('response');
  }
  closeDialog() {
    this.openAlert = false;
    this.dialog = false;
  }
  get dialogText() {
    return this.item.IsApproved
      ? 'Would you like to place this item on hold ?'
      : 'Would you like to approve this item?';
  }
  get dialogBtnText() {
    return this.item.IsApproved ? 'Place this Item on hold' : 'Approve This Item';
  }
}
