
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import ItemService from '@/services/item-service';
import { QuickDialog } from '@/store/models/DialogModel';
import { Item } from '@/store/models/ItemModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DialogLayout from './DialogLayout.vue';

@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop({ required: true }) item!: Item;
  /* Store Actions */
  /* Watchers */
  @Watch('newPartNumber')
  onNewPartNumberChanged() {
    this.checkForDuplicates();
  }
  /* Data */
  dialog: boolean = false;
  shouldShowDuplicateAlert: boolean = false;
  isLoading: boolean = false;
  isCheckingForDuplicates = false;
  isPartNumberValid = false;
  defaultDialogData: QuickDialog | null = null;
  newPartNumber: string = '';
  /* Utility Functions */
  async openDialog() {
    this.defaultDialogData = ConversionHelper.convertQuickDialog(this.item);
    this.dialog = true;
    this.newPartNumber = this.item.PartNumber;
  }
  closeDialog() {
    this.dialog = false;
  }
  async ChangePartNumber(shouldOverride: boolean = false) {
    this.isLoading = true;
    try {
      await ItemService.ChangePartNumber(
        this.item.Id,
        this.newPartNumber,
        shouldOverride
      );
      this.$emit('partNumberChanged');
    } catch (err) {
      SetSnackBar('There was an error changing this part number');
    }
    this.isLoading = false;
  }
  async checkForDuplicates() {
    this.isCheckingForDuplicates = true;
    setTimeout(async () => {
      if (
        this.newPartNumber &&
        this.newPartNumber.toLowerCase() !== this.item.PartNumber.toLowerCase()
      ) {
        const response = await ItemService.IsDuplicatePartNumber(this.newPartNumber);
        if (response.data && response.data.DupsFound)
          this.shouldShowDuplicateAlert = true;
        else {
          this.shouldShowDuplicateAlert = false;
        }
        this.isCheckingForDuplicates = false;
      }
      this.isCheckingForDuplicates = false;
    }, 100);
  }
  resetNewPartNumber() {
    this.newPartNumber = this.item.PartNumber;
  }
  goToMergeItem() {
    this.$emit('openItemMergeDialog');
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
