
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { QuickDialog } from '@/store/models/DialogModel';
import { Item } from '@/store/models/ItemModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DialogLayout from './DialogLayout.vue';
@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) firstItem!: Item;
  /* Store Actions */
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  shouldShowDuplicateAlert: boolean = false;
  isLoading: boolean = false;
  isCheckingForDuplicates = false;
  isPartNumberValid = false;
  defaultDialogData: QuickDialog | null = null;
  newPartNumber: string = '';
  /* Utility Functions */
  async openDialog() {
    this.defaultDialogData = ConversionHelper.convertQuickDialog(this.firstItem);
    this.dialog = true;
    this.newPartNumber = this.firstItem.PartNumber;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
