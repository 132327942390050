
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '@/store/models/ItemModel';
import DialogLayout from './DialogLayout.vue';
import { ItemService } from '@/services/item-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { QuickDialog } from '@/store/models/DialogModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop({ required: true }) item!: Item;
  @Prop() isImageDoneProccessing!: boolean;
  /* Store Actions */
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.dialog = false;
    }
  }
  /* Data */
  isOrderingProof: boolean = false;
  isGettingDigitalProof: boolean = false;
  dialog: boolean = false;
  data: QuickDialog | null = null;
  /* Methods */
  /* Computed */
  async download() {
    await this.downloadProof();
  }
  async downloadProof() {
    try {
      this.isGettingDigitalProof = true;

      const response = await ItemService.StartDigitalProof(this.item.Id);
      this.HasProofBeenProcessed(response.data);
    } catch (err) {
      this.isGettingDigitalProof = false;
      SetSnackBar(
        'There was an error downloading your press proof. Please contact Technical support'
      );
    }
  }
  routeToApprovalPage() {
    this.$router.push({
      name: 'ItemApproval',
      params: { id: this.item.Id }
    });
  }
  async orderPhysicalProofClick() {
    this.dialog = false;
    this.$emit('openOrderProofDialog');
  }
  async HasProofBeenProcessed(fileId: string) {
    try {
      let refreshId = setInterval(async () => {
        const hasProofBeenProcessed = await ItemService.GetSupportingFileRaw(fileId);
        if (hasProofBeenProcessed.data.HasProcessed) {
          await ItemService.GetDigitalProof(
            hasProofBeenProcessed.data.PublicId,
            `${this.item.PartNumber} - DigitalProof.pdf`
          );
          this.isGettingDigitalProof = false;
          clearInterval(refreshId);
        }
      }, 5000);
    } catch (err) {
      this.isGettingDigitalProof = false;
      SetSnackBar(
        'There was an error downloading your press proff. Please contact Technical support'
      );
    }
  }
  openDialog() {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Mounted */
  async mounted() {}
  /* Created */
  created() {}
  /* Created */
}
