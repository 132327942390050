
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '@/store/models/ItemModel';
import DialogLayout from './DialogLayout.vue';
import { QuoteData } from '../store/models/QuotesModel';
import { ItemService } from '@/services/item-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { QuickDialog } from '@/store/models/DialogModel';
import DieService from '@/services/die-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop({ required: true }) item!: Item;
  @Prop({ default: '' }) fileName!: string;
  @Prop() items!: Item[];
  /* Store Actions */
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.dialog = false;
    }
  }
  /* Data */
  isGettingPdfArtwork: boolean = false;
  isGettingOriginalArtwork: boolean = false;
  isGettingDigitalProof: boolean = false;
  dialog: boolean = false;
  data: QuickDialog | null = null;
  /* Methods */
  /* Computed */
  async download(option: number) {
    const itemsToDownload = [];
    if (this.items) itemsToDownload.push(...this.items);
    else itemsToDownload.push(this.item);
    switch (option) {
      case 0:
        this.isGettingOriginalArtwork = true;
        try {
          for (const item of itemsToDownload) {
            const response = await ItemService.GetItemDetails(item.Id);
            let fileName = '';
            if (response.data) {
              fileName = response.data.FileName;
            }
            await ItemService.getOriginalArtwork(item.Id, fileName);
          }
          this.dialog = false;
        } catch {
          SetSnackBar(
            'There was an error downloading your press proof. Please contact Technical support'
          );
        }
        this.isGettingOriginalArtwork = false;
        break;

      case 1:
        this.isGettingPdfArtwork = true;
        try {
          for (const item of itemsToDownload) {
            await ItemService.getOriginalArtworkAsPdf(item.Id, item.PartNumber);
          }
          this.dialog = false;
        } catch {
          SetSnackBar(
            'There was an error downloading your press proof. Please contact Technical support'
          );
        }

        this.isGettingPdfArtwork = false;

        break;
      case 2:
        await this.onProofDownloadClick();
        break;
    }
  }
  async onProofDownloadClick() {
    try {
      this.isGettingDigitalProof = true;

      const response = await ItemService.StartDigitalProof(this.item.Id);
      this.HasProofBeenProcessed(response.data);
    } catch (err) {
      this.isGettingDigitalProof = false;
      SetSnackBar(
        'There was an error downloading your press proof. Please contact Technical support'
      );
    }
  }
  async HasProofBeenProcessed(fileId: string) {
    try {
      let refreshId = setInterval(async () => {
        const hasProofBeenProcessed = await ItemService.GetSupportingFileRaw(fileId);
        if (hasProofBeenProcessed.data.HasProcessed) {
          await ItemService.GetDigitalProof(
            hasProofBeenProcessed.data.PublicId,
            `${this.item.PartNumber} - ${this.item.Description} - DigitalProof.pdf`
          );
          this.dialog = false;
          this.isGettingDigitalProof = false;
          clearInterval(refreshId);
        }
      }, 5000);
    } catch (err) {
      this.isGettingDigitalProof = false;
      SetSnackBar(
        'There was an error downloading your press proff. Please contact Technical support'
      );
    }
  }
  async downloadDieTemplate() {
    let dieTemplate = this.$store.getters['dies/getDieById'](this.item.Die.Id);
    DieService.DownloadDieTemplates(dieTemplate);
  }
  openDialog() {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Mounted */
  async mounted() {}
  /* Created */
  created() {}
  /* Created */
}
